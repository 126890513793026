<template>
  <div class="news-page">
    <MainNav />
    <div class="news-bar">
      <div class="bannerWrapper">
        <img src="../assets/info/info-big.png" alt="" />
      </div>
      <div class="newsListNav">
        <div class="maskLayer"></div>
        <div class="ListNav">
          <div class="itemWrapper">
            <div
              @click="changeOptions(0)"
              :class="[typeMark == 0 ? 'active' : '']"
            >
              企业资讯
            </div>
            <div
              @click="changeOptions(1)"
              :class="[typeMark != 0 ? 'active' : '']"
            >
              行业动态
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="newsContainer" v-if="news.length > 0">
      <div class="newsItem" v-for="(item, index) in news" :key="index">
        <p>{{ item.title }}</p>
        <template v-if="item.c && item.c.length > 0">
          <template
            class="paragraph"
            v-for="(subItem, subIndex) in news.c" 
          >
            <template v-for="subItem" :key="subIndex"> </template>
          </template>
        </template>
      </div>
      <div class="newsPagination">
        <div class="prev" v-if="news[no].prev !== ''">
          上一篇：<span @click="toPrevious">{{ news[no].prev }}</span>
        </div>
        <div class="next" v-if="news[no].next !== ''">
          下一篇：<span @click="toNext">{{ news[no].next }}</span>
        </div>
      </div>
    </div>
    <div v-else>
      no content!
      <img src="" />
    </div> -->

    <!-- <rightNav /> -->
    <Footer />
  </div>
</template>

<script>
import MainNav from "../components/MainNav/index";
import Footer from "../components/Footer.vue";
// import rightNav from "../components/RightNav/RightNav";
const newData = require("../utils/data.json");

export default {
  name: "NewsDetail",
  data() {
    return {
      typeMark: this.$route.query.newType,
      news: null,
    };
  },
  computed: {
    no: function () {
      let no_ = Number(this.$route.query.no) - 1;
      return no_;
    },
  },
  components: {
    MainNav,
    Footer,
    // rightNav,
  },
  mounted() {
    // this.toPinglun()
    this.news = newData[this.typeMark];
  },
  methods: {
    // toPinglun() {
    //   this.$refs["wrapper"].scrollIntoView(true);
    // },
    changeOptions(bool) {
      this.typeMark = this.typeMark == "company" ? "industry" : "company";
      this.news = newData[this.typeMark];
      this.no = 0;
    },
    toPrevious() {
      if (this.news[this.no - 1]) {
        this.no--;
      }
    },
    toNext() {
      if (this.news[this.no + 1]) {
        this.no++;
      }
    },
    allPrpos(obj) {
      // let props = [];
      // props = obj.map((i) => {
      //   if (typeof obj[i] == "function") {
      //     obj[i]();
      //   } else {
      //     i: item;
      //   }
      // });
      return props;
    },
  },
};
</script>

<style scoped lang="less">
.news-page {
  .news-bar {
    height: auto;
    position: relative;
    margin-bottom: 2%;
    .bannerWrapper {
      width: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .newsListNav {
      height: 8rem;
      position: absolute;
      z-index: 0;
      bottom: 0;
      width: 100%;
      .maskLayer {
        position: absolute;
        background-color: #000843;
        opacity: 0.36;
        width: 100%;
        height: 100%;
        left: 0;
      }
      .ListNav {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        .itemWrapper {
          display: flex;
          justify-content: center;
          align-items: stretch;
          div {
            margin: 0 4rem;
            display: flex;
            box-sizing: border-box;
            align-items: center;
            justify-content: center;
            font-size: 1.8rem;
            color: rgb(176, 194, 238);
            cursor: pointer;
            &:hover {
              color: #ffffff;
            }
          }
          .active {
            border-bottom: 0.33rem solid #fbde00;
            color: #fff;
          }
        }
      }
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  .pagenav {
    margin-top: 2.71%;
    margin-bottom: 4.67%;
    text-align: center;
    .pagination {
      li {
        .active {
          background-color: #2b8dfa;
          border-color: #2b8dfa;
          color: #ffffff;
        }
        a {
          margin-right: 0.83rem;
        }
        a:hover {
          border-color: #2b8dfa;
          background-color: #ffffff;
        }
        a:focus {
          background-color: #2b8dfa;
          border-color: #2b8dfa;
          color: #ffffff;
        }
      }
    }
  }
}
</style>